import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useContext, memo } from 'react';
import { useReactiveVar, useSubscription } from '@apollo/client';
import { RiArrowLeftSLine, RiMenuFill } from 'react-icons/ri';

import { cache } from 'services/apollo';
import Suspense from 'services/Suspense';
import { useTwilioContext } from 'lib/twilio';
import useScreenType from 'hooks/useScreenType';
import { ALERT_BAR_HEIGHT, WORKSPACE_DETAIL_BAR_WIDTH_MOBILE } from 'constants/commons';
import { AuthContext } from 'contexts/auth/AuthContext';
import { CALL_STATS_SUBSCRIPTION } from 'graphql/dashboard';
import sendPingMessage from 'components/utils/sendPingMessage';
import { WORKSPACE_MEMBERS } from 'graphql/foundation';
import { MEMBER_STATUS_SUBSCRIPTION } from 'graphql/organization/member';
import {
  userOnlineStatus,
  isNetworkAvailable,
  showCallboxContactLog,
  showWorkspaceDetailDrawer,
  showCallboxContactDetail,
  showCallboxSearchDrawer,
  showMemberChatSearchDrawer,
  showMemberChatMemberDetail,
} from 'services/apollo/reactiveVars';
import { initialWorkspaceMemberParams } from 'components/pages/settings/members/hooks/useMembersQVars';
import { useChatBot } from 'hooks/useChatBot';
import { useAllWorkspaceNumbers } from 'hooks/useAllWorkspaceNumbers';

import * as S from './Styles';
import WorkspaceBar from './WorkspaceBar';
import useMemberOnlineStatus from './members/useMemberOnlineStatus';
import SettingsSidebar from '../settings-sidebar';

interface INumber {
  id: string;
  number: string;
  name: string;
}

function SideWorkspaceBar() {
  const location = useLocation();
  const { teamId } = useParams<Record<string, string | undefined>>();
  const { state: twilliowState, deviceStatus } = useTwilioContext();
  const { updateMemberOnlineStatus } = useMemberOnlineStatus();
  const hasSettingRoute = location.pathname.split('/').includes('settings');
  const hasDashboardRoute = location.pathname.split('/').includes('dashboard');
  const hasNumberBuyRoute = location.pathname.split('/settings').includes('/numbers/buy');
  const isDeviceOffline = deviceStatus === 'offline';
  const {
    accessToken,
    loggedInMemberId,
    isAlertBarVisible,
    activeWorkspaceId = '',
    isUnderReview,
  } = useContext(AuthContext);
  const isUserOnline = useReactiveVar(userOnlineStatus);
  const { isLaptop, isTablet, isMobile } = useScreenType();

  const showWorkspaceDetailSidebar = useReactiveVar(showWorkspaceDetailDrawer);
  const toggleWorkspaceDetailMenu = () => {
    showWorkspaceDetailDrawer(!showWorkspaceDetailSidebar);
    showCallboxContactDetail(false);
    showCallboxContactLog(false);
    showCallboxSearchDrawer(false);
    showMemberChatSearchDrawer(false);
    showMemberChatMemberDetail(false);
  };
  const internetConnection = useReactiveVar(isNetworkAvailable);
  const { numbers } = useAllWorkspaceNumbers();

  const { updateNumberDetail, updateActiveNumberList } = useChatBot();

  const channelIdList = numbers?.map((eachChannel: INumber) => eachChannel?.id);
  const activeNumberList = numbers?.map((channel: INumber) => channel?.number);

  useEffect(() => {
    updateNumberDetail(channelIdList?.length ?? 0);
    updateActiveNumberList(activeNumberList ?? []);
  }, [channelIdList, updateNumberDetail, updateActiveNumberList, activeNumberList]);

  const { data } = useSubscription(MEMBER_STATUS_SUBSCRIPTION, {
    variables: {
      workspace: activeWorkspaceId,
    },
    onSubscriptionData: (arg: any) => {
      const { id, online } = arg?.subscriptionData?.data?.onlineMemberStatus?.message;
      updateMemberOnlineStatus(id, online, teamId);
    },
  });

  /* this useEffeect is fired when interent disconnects, it sets user offline on internet
  disconnection so that accessToken can be sent on internet restoration */
  useEffect(() => {
    if (!internetConnection) updateMemberOnlineStatus(loggedInMemberId, false, teamId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internetConnection]);

  const { data: callStatSubscriptionData } = useSubscription(CALL_STATS_SUBSCRIPTION, {
    variables: {
      channels: channelIdList ?? [],
    },
    onSubscriptionData: (arg: any) => {
      const callStatus = arg?.subscriptionData?.data?.callStats?.message?.callStatus;
      const direction = arg?.subscriptionData?.data?.callStats?.message?.direction;
      if (callStatus === 'InProgress' || callStatus === 'Completed') {
        arg?.client?.cache?.modify({
          fields: {
            liveCalls: () => {},
            callStats: () => {},
          },
        });
      }
      if (callStatus === 'NoAnswer' && direction === 'Incoming') {
        arg?.client?.cache?.modify({
          fields: {
            missedCalls: () => {},
            callStats: () => {},
          },
        });
      }
    },
  });

  useEffect(() => {
    const pingMessage = () => {
      const cachedData: any = cache.readQuery({
        query: WORKSPACE_MEMBERS,
        variables: {
          pageParams: initialWorkspaceMemberParams,
        },
      });
      const memberList = cachedData?.allWorkspaceMembers?.data?.edges ?? [];
      sendPingMessage({
        memberList,
        loggedInMemberId,
        isUserOnline,
        accessToken,
      });
    };
    if (isUserOnline) {
      pingMessage();
    }
    const interval = setInterval(pingMessage, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [isUserOnline, loggedInMemberId, accessToken]);

  return (
    <>
      <Suspense>
        {isLaptop || isTablet || isMobile ? (
          <S.Hamburger
            className={
              (hasSettingRoute && !hasNumberBuyRoute) || hasDashboardRoute ? '' : 'invisible'
            }
            top={
              isAlertBarVisible || isUnderReview || isDeviceOffline
                ? `${ALERT_BAR_HEIGHT + 14}px`
                : '16px'
            }
            icon={<RiMenuFill />}
            onClick={toggleWorkspaceDetailMenu}
          />
        ) : (
          <WorkspaceBar />
        )}
      </Suspense>

      <S.WorkspaceDetailDrawer
        width={WORKSPACE_DETAIL_BAR_WIDTH_MOBILE}
        placement='left'
        closable={true}
        onClose={toggleWorkspaceDetailMenu}
        visible={showWorkspaceDetailSidebar}
        alertBarHeight={isAlertBarVisible || isDeviceOffline ? ALERT_BAR_HEIGHT : 0}
        closeIcon={
          hasSettingRoute ? <RiArrowLeftSLine size={22} onClick={toggleWorkspaceDetailMenu} /> : ''
        }
      >
        {hasSettingRoute ? <SettingsSidebar /> : <WorkspaceBar />}
      </S.WorkspaceDetailDrawer>
    </>
  );
}

export default memo(SideWorkspaceBar);
