import React from 'react';
import styled from 'styled-components';

export interface AuxProps {
  children: React.ReactNode;
  hasWorkspace?: string;
  hideSideNav?: boolean;
}

const Wrapper = styled(({ showNavBar, hideSideNav, ...rest }) => <main {...rest} />)<{
  // Temporary fix to handle state for no workspace
  showNavBar: string;
}>`
  display: ${props => (props.hideSideNav ? 'block' : 'grid')};
  height: ${props => (props.hideSideNav ? '100%' : '100vh')};
  width: 100vw;
  grid-template-columns: ${props => props.gridTemplateColumns ?? '60px auto'};
  grid-template-rows: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  .sideNavbar-container {
    z-index: 2000;
  }
`;

const Container = ({ children, hasWorkspace, hideSideNav }: AuxProps) => {
  return (
    <Wrapper showNavBar={hasWorkspace} hideSideNav={hideSideNav}>
      {children}
    </Wrapper>
  );
};

export default React.memo(Container);
