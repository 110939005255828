import { ApolloLink } from '@apollo/client';
import { Observable } from '@apollo/client/core'; // Make sure to import Observable
import SecureLS from 'secure-ls';

import { authTokenNeeded, refreshTokenNeeded, tokenNotNeeded } from './constants';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });
export type Headers = {
  authorization?: string;
  lang?: string;
};

export function getToken(operation: any): string {
  let tokens: any = {};
  try {
    const data = ls.get('_tokens') ?? JSON.stringify('');
    tokens = data && JSON?.parse?.(data);
  } catch (e) {
    console.warn(e);
  }
  if (authTokenNeeded.includes(operation?.operationName)) {
    return tokens.authToken;
  }
  if (tokenNotNeeded.includes(operation?.operationName)) {
    return '';
  }
  if (refreshTokenNeeded.includes(operation?.operationName)) {
    return tokens.refreshToken;
  }
  return tokens.accessToken;
}

const authLink = new ApolloLink((operation, forward) => {
  // type check what getToken returns
  const token: string = getToken(operation);
  // TODO: NEED TO IDENTIFY ALL THE MUTATIONS WHERE TOKEN IS NOT NEEDED
  // if (!tokenNotNeeded.includes(operation.operationName) && !token) {
  //   // PREVENT API CALLS IF TOKEN IS NOT FOUND IN CASE OF TOKEN NEEDED APIS
  //   return new Observable(observer => {
  //     observer.complete();
  //   });
  // }
  const locale = localStorage.getItem('locale') ?? 'en';
  operation.setContext(({ headers }: { headers: Headers }) => ({
    headers: {
      ...headers,
      ...(token && token?.length > 0 && { authorization: `JWT ${token}` }),
      lang: locale,
      // authorization: token ? `JWT ${token}` : null
    },
  }));

  return forward(operation);
  // CODE COMMENTED DUE TO BLANK PAGE RENDER ISSUES
  // return forward(operation).map(data => {
  //   // ...modify result as desired here...
  //   const error = data?.data?.[operation?.operationName]?.error;
  //   if (error && error?.errorKey === 'under_review') {
  //     if (!window.location.pathname.includes('dashboard')) {
  //       // Redirect to dashboard in order to fetch latest workspace data and show under review dialog box
  //       window.location.replace('/dashboard');
  //     }
  //   }
  //   return data;
  // });
});

export default authLink;
