import styled from 'styled-components';

export const CountrySelectInputWrapper = styled.div`
  width: fit-content;
  flex: 0;
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) {
    .ant-select-selector {
      padding: 0;
      height: 14px;
      background: none;
      border: none;
      .ant-select-selection-item {
        padding: 0;
        img {
          min-width: 20px;
          width: 100%;
          height: 12px;
          border-radius: 2px;
          object-fit: cover;
          &[alt='NP'] {
            min-width: 11px;
            height: 14px;
          }
        }
      }
    }
    /* dropdown */
    .ant-select-dropdown {
      top: 26px !important;
      padding: 6px 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      border-color: var(--Stroke-100-Default, #e5e4e7);
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
      .ant-select-item {
        padding: 6px 14px;
      }
      /* search input */
      .ant-input-affix-wrapper {
        padding: 6px 14px;
        height: 36px !important;
        .ant-input-prefix {
          margin-right: 10px;
          > svg {
            width: 24px;
            height: 24px;
            color: #4d4950;
          }
        }
        .ant-input {
          padding: 0;
          color: var(--Text-500-Component, #333036);
          &,
          &::placeholder {
            font-family: Heebo;
            font-size: 14px;
            font-weight: normal;
          }
          &::placeholder {
            color: var(--Text-300-Component-2, #66616b);
          }
        }
      }
    }
  }
`;

export const SelectCountryOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  color: var(--Text-500-Component, #333036);
  font-size: 14px;
  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
    top: -1px;
  }
  svg {
    font-size: 20px;
    color: #3d3358;
    vertical-align: middle;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 22px;
    font-weight: 400;
    color: #3d3358;
    &.truncate {
      width: 190px;
    }
  }
`;
