import SecureLS from 'secure-ls';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { ApolloClient, from, split, ApolloLink } from '@apollo/client';
import { geUserTimeZone } from 'components/utils/datetime';

import {
  API_ENDPOINT,
  SUBSCRIPTION_ENDPOINT,
  ANALYTICS_ENDPOINT,
  ANALYTICS_SUB_ENDPOINT,
  BULK_SMS_API_ENDPOINT,
  AUTOMATION_API_ENDPINT,
  SALES_DIALER_API_ENDPINT,
  SALES_DIALER_SUB_ENDPOINT,
  CRM_URI,
} from 'constants/endpoint';

import tokenRefresh from './tokenRefresh';
import useErrorLink from './errorLink';
import authLink from './authLink';
import cache from './cache';
import { customFetch } from './helpers';
import { salesDialerSub } from './constants';

const ls = new SecureLS({ encodingType: 'aes', isCompression: false });

const debug = process.env.REACT_APP_DEBUG === 'true';

const uploadLink = createUploadLink({
  uri: API_ENDPOINT ?? Cypress.env('api_endpoint'),
  fetch: customFetch as any,
});

// will move to utils
const getToken = () => {
  let user: any = {};
  try {
    const data = ls.get('_tokens') ?? JSON.stringify('');
    user = data && JSON.parse(data);
  } catch (e) {
    console.warn(e);
  }
  const token = user?.accessToken || '';
  return token;
};
// eslint-disable-next-line import/no-mutable-exports
export let activeSocket: { send: (arg0: string) => void };

export const wsLink: any = new GraphQLWsLink(
  createClient({
    url: `${SUBSCRIPTION_ENDPOINT}/`,
    lazy: true,
    connectionParams: () => {
      return {
        accessToken: getToken(),
      };
    },
  }),
);
export const salesdialerWsLink: any = new GraphQLWsLink(
  createClient({
    url: `${SALES_DIALER_SUB_ENDPOINT}/`,
    lazy: true,
    connectionParams: () => {
      return {
        accessToken: getToken(),
      };
    },
  }),
);

export const dashWsLink: any = new GraphQLWsLink(
  createClient({
    url: `${ANALYTICS_SUB_ENDPOINT}/`,
    lazy: true,
    connectionParams: () => {
      return {
        accessToken: getToken(),
      };
    },
  }),
);
// eslint-disable-next-line no-var
if (wsLink) {
  wsLink.client.on('connecting', () => {
    if (debug) console.log('ws connecting');
  });

  wsLink.client.on('connected', (socket: any) => {
    if (debug) console.log('ws connected', socket);
    activeSocket = socket;
  });
  wsLink.client.on('ping', () => {
    if (debug) console.log('ws ping');
  });
  wsLink.client.on('pong', () => {
    if (debug) console.log('ws pong');
  });
  wsLink.client.on('message', () => {
    if (debug) console.log('ws message');
  });

  wsLink.client.on('closed', () => {
    if (debug) console.log('ws closed');
  });
  wsLink.client.on('error', () => {
    if (debug) console.log('ws error');
  });
}

if (salesdialerWsLink) {
  salesdialerWsLink.client.on('connecting', () => {
    if (debug) console.log('salesdialer ws connecting');
  });

  salesdialerWsLink.client.on('connected', (socket: any) => {
    if (debug) console.log('salesdialer ws connected', socket);
    // activeSocket = socket;
  });
  salesdialerWsLink.client.on('ping', () => {
    if (debug) console.log('salesdialer ws ping');
  });
  salesdialerWsLink.client.on('pong', () => {
    if (debug) console.log('salesdialer ws pong');
  });
  salesdialerWsLink.client.on('message', () => {
    if (debug) console.log('salesdialer ws message');
  });

  salesdialerWsLink.client.on('closed', () => {
    if (debug) console.log('salesdialer ws closed');
  });
  salesdialerWsLink.client.on('error', () => {
    if (debug) console.log('salesdialer ws error');
  });
}

export const resetWSLink = (callback?: () => void) => {
  if (debug) console.log('reset ws link');
  wsLink.client.subscribe();
  salesdialerWsLink?.client?.subscribe();
  setTimeout(() => {
    if (callback) {
      callback();
    }
  }, 1000);
};

// Create a custom WebSocket link manager
const createCustomWsLink = (operation: any, forward: any) => {
  const definition = getMainDefinition(operation.query);
  if (definition.kind === 'OperationDefinition' && definition.operation === 'subscription') {
    // Define your custom logic to route subscriptions to different WebSocket links
    if (salesDialerSub.includes(operation?.operationName)) {
      return salesdialerWsLink.request(operation) || forward(operation);
    }
    return wsLink.request(operation) || forward(operation);
  }
  return forward(operation);
};

// Combine the custom WebSocket link with the HTTP link
const customLink = new ApolloLink((operation, forward) => {
  return createCustomWsLink(operation, forward);
});

interface Definition {
  kind: string;
  operation?: string;
}

function useApolloService() {
  // this is basically telling when to use http link and ws link
  const defaultLink = split(
    ({ query }) => {
      const { kind, operation }: Definition = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    // wsLink,
    customLink,
    // eslint-disable-next-line prettier/prettier
    authLink.concat((uploadLink as unknown) as ApolloLink),
  );

  const multiClientLink = new MultiAPILink({
    endpoints: {
      dash: ANALYTICS_ENDPOINT as string,
      bulkSms: BULK_SMS_API_ENDPOINT as string,
      automation: AUTOMATION_API_ENDPINT as string,
      salesDialer: SALES_DIALER_API_ENDPINT as string,
      crmIntegrateEndPoint: CRM_URI as string,
      crmConnectBitrixUrl: `${CRM_URI}/bitrix24` as string,
      crmConnectZendeskUrl: `${CRM_URI}/zendesk` as string,
    },
    createHttpLink: () => createUploadLink() as any,
    createWsLink: () => dashWsLink,
    httpSuffix: '/graphql/',
    wsSuffix: '/graphql/ws',
    getContext: (endpoint: string) => {
      return {
        headers: {
          Authorization: `JWT ${getToken()}`,
          tz: geUserTimeZone(),
        },
      };
    },
  });

  const { errorLink } = useErrorLink();

  const client = new ApolloClient({
    cache: cache as any,
    link: from([tokenRefresh, errorLink, multiClientLink, defaultLink]),
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy(lastFetchPolicy) {
          if (lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'network-only') {
            return 'cache-first';
          }
          return lastFetchPolicy;
        },
      },
    },
  });

  return { client };
}

if (process.env.NODE_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

export default useApolloService;

export { cache };
